import type { SVGProps } from "react"

export const SolarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="30"
      height="19"
    >
      <path
        d="M2.71586 1.37448C2.84152 0.582808 3.52414 0 4.32572 0H25.0146C25.8162 0 26.4988 0.582809 26.6245 1.37448L29.041 16.5988C29.1981 17.5886 28.4333 18.4844 27.4312 18.4844H1.90916C0.907003 18.4844 0.142189 17.5886 0.299294 16.5988L2.71586 1.37448Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)" fill="currentColor">
      <rect width="13.7899" height="8.68767" rx="0.815009" />
      <rect y="9.79663" width="13.7899" height="8.68767" rx="0.815009" />
      <rect x="15.5508" width="13.7899" height="8.68767" rx="0.815009" />
      <rect
        x="15.5508"
        y="9.79663"
        width="13.7899"
        height="8.68767"
        rx="0.815009"
      />
    </g>
    <path
      d="M10.5952 14.6702V25.2653C10.5952 25.7154 10.9601 26.0803 11.4102 26.0803H17.9303C18.3804 26.0803 18.7453 25.7154 18.7453 25.2653V14.6702"
      stroke="currentColor"
      strokeWidth="1.22251"
      strokeLinejoin="round"
    />
  </svg>
)

export default SolarIcon
